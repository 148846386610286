.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  /* Dark overlay with 50% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  /* Make sure it's above other content */
}

.fancy-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;

  div {
    position: absolute;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    &.ring {
      border-width: 0.5rem;
      border-style: solid;
      border-color: transparent;
      animation: 2s fancy infinite alternate;

      &:nth-child(1) {
        border-left-color: #7B68EE;
        border-right-color: #7B68EE;
      }

      &:nth-child(2) {
        border-top-color: #7B68EE;
        border-bottom-color: #7B68EE;
        animation-delay: 1s;
      }
    }

    &.dot {
      width: 1rem;
      height: 1rem;
      background: #7B68EE;
    }
  }
}

@keyframes fancy {
  to {
    transform: rotate(360deg) scale(0.5);
  }
}

.verfication-notification {
  position: fixed;
  right: -200%;
  top: 125px;
  background-color: #16C75D;
  color: white;
  padding: 23px;
  box-sizing: border-box;
  border-radius: 12px 0 0 12px;
  transition: right 0.5s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  font-family: "Roboto", arial, sans-serif;
  font-weight: bold;
  font-style: normal;
}

.verfication-notification-error {
  position: fixed;
  right: -200%;
  top: 125px;
  background-color: #cc0000;
  color: white;
  padding: 23px;
  box-sizing: border-box;
  border-radius: 12px 0 0 12px;
  transition: right 0.5s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 12px;
  font-family: "Roboto", arial, sans-serif;
  font-weight: bold;
  font-style: normal;
}

.verfication-notification .check-icon {
  line-height: 0;
}

.verfication-notification.show {
  right: 0;
}

.verfication-notification-error.show {
  right: 0;
}